<template>
  <div v-if="(options.fotosItem && options.fotosItem.length) ||
    (options.fotosProcesso && options.fotosProcesso.length) ||
    (options.modalPhotos && options.modalPhotos.length)">
    <button
      v-if="options.iconWithButton"
        class="buttonView btn"
        :style="iconViewImagemStyle"
        @click="$refs.modalPhotoView.show()"
      >
      <eye-icon
        v-b-tooltip.hover.top="options.tooltip || ''"
        v-on:click="$refs.modalPhotoView.show()"
      />
      Ver fotos
    </button>
    <eye-icon
      v-else
      v-b-tooltip.hover.top="options.tooltip || 'Visualizar fotos'"
      v-on:click="$refs.modalPhotoView.show()"
      :style="iconViewImagemStyle"
    />
    <Modal
      ref="modalPhotoView"
      :title="options.title || 'Ver fotos do material'"
      no-close-on-esc
      size="lg"
    >
      <BCarousel>
        <b-carousel
          id="carousel-1"
          style="text-shadow: 0px 0px 2px #000"
          controls
          indicators
          img-width="1024"
          img-height="480"
          :interval="0"
        >
          <b-col
            v-for="(fotos, index) in (options.fotosItem || options.fotosProcesso || options.modalPhotos)"
            v-bind:key="index"
          >
            <b-carousel-slide>
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  width="1024"
                  height="480"
                  :src="fotos.url"
                  alt="image slot"
                >
              </template>
            </b-carousel-slide>
          </b-col>
        </b-carousel>
      </BCarousel>
    </Modal>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';

import Modal from '@/components/Utils/Modal';

export default {
  name: 'PhotoView',
  components: {
    Modal,
    BCarousel,
    BCarouselSlide,
  },

  props: {
    isBusy: {
      type: Boolean,
    },
    options: {
      type: Object,
      required: false,
    },
    position: {
      type: String,
      default: 'absolute',
    },
    margin: {
      type: String,
      default: '0 0 0 0',
    },
    color: {
      type: String,
      default: '#5E627A',
    },
  },

  computed: {
    iconViewImagemStyle() {
      return {
        color: this.options.color || this.color,
        position: this.options.position || this.position,
        margin: this.options.margin || this.margin,
        cursor: 'pointer',
      };
    },
  },
};

</script>

<style scoped>
.buttonView {
  display: flex;
  background: #F0F1FC;
  width: 9rem;
  gap: 0.7rem
}

.buttonView:hover {
  background: #E6E8F2;
}

</style>
