/* eslint-disable max-len, no-restricted-syntax */
import {
  MapPinIcon, SettingsIcon, MenuIcon, UserIcon, ArrowLeftIcon, ArrowDownIcon, ArrowUpIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon, BarChart2Icon, CheckIcon, FileTextIcon, LogInIcon, LogOutIcon, XIcon, Trash2Icon, AwardIcon, CheckCircleIcon, EyeIcon, XCircleIcon, BriefcaseIcon, HomeIcon, ClipboardIcon, FolderPlusIcon, BoxIcon, TagIcon, NavigationIcon, WindIcon, PlusIcon, SlidersIcon, DropletIcon, ShoppingCartIcon, HeartIcon, ScissorsIcon, XOctagonIcon, ActivityIcon, PackageIcon, UsersIcon, AlertCircleIcon, ArchiveIcon, ThermometerIcon, Minimize2Icon, Maximize2Icon, EditIcon, SunIcon, MoonIcon, LayersIcon, XSquareIcon, PrinterIcon, SpeakerIcon, RotateCwIcon, ServerIcon, TruckIcon, CameraIcon, CopyIcon, AlertTriangleIcon, InfoIcon, PaperclipIcon, RepeatIcon, MoreHorizontalIcon, ExternalLinkIcon, MonitorIcon, PlayCircleIcon, BookOpenIcon, CircleIcon, ClockIcon, RefreshCwIcon, BookIcon, FilePlusIcon, FileMinusIcon, PocketIcon, TrendingUpIcon, UserPlusIcon, FlagIcon,
} from 'vue-feather-icons';

const DashboardIcon = {
  name: 'DashboardIcon',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        stroke: 'none',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M12.984 3h8.016v6h-8.016v-6zM12.984 21v-9.984h8.016v9.984h-8.016zM3 21v-6h8.016v6h-8.016zM3 12.984v-9.984h8.016v9.984h-8.016z',
      },
    })]);
  },
};

// const EditIcon = {
//   name: 'EditIcon',
//   functional: true,
//   render: function render(h, ctx) {
//     return h("svg", {
//       attrs: {
//         xmlns: "http://www.w3.org/2000/svg",
//         width: "24",
//         height: "24",
//         viewBox: "0 0 24 24",
//         fill: "currentColor",
//         stroke: "none",
//         "stroke-width": "2",
//         "stroke-linecap": "round",
//         "stroke-linejoin": "round"
//       },
//       ...ctx.data,
//     }, [h("path", {
//       attrs: {
//         d: "M21.7 7.3l-5-5c-0.4-0.4-1-0.4-1.4 0l-13 13c-0.2 0.2-0.3 0.4-0.3 0.7v5c0 0.6 0.4 1 1 1h5c0.3 0 0.5-0.1 0.7-0.3l13-13c0.4-0.4 0.4-1 0-1.4zM7.6 20h-3.6v-3.6l12-12 3.6 3.6-12 12z",
//       },
//     })]);
//   }
// };

const SearchIcon = {
  name: 'SearchIcon',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        stroke: 'none',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M9.516 14.016c2.484 0 4.5-2.016 4.5-4.5s-2.016-4.5-4.5-4.5-4.5 2.016-4.5 4.5 2.016 4.5 4.5 4.5zM15.516 14.016l4.969 4.969-1.5 1.5-4.969-4.969v-0.797l-0.281-0.281c-1.125 0.984-2.625 1.547-4.219 1.547-3.609 0-6.516-2.859-6.516-6.469s2.906-6.516 6.516-6.516 6.469 2.906 6.469 6.516c0 1.594-0.563 3.094-1.547 4.219l0.281 0.281h0.797z',
      },
    })]);
  },
};

const WarehouseIcon = {
  name: 'WarehouseIcon',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 640 512',
        fill: 'currentColor',
        stroke: 'none',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M504 352H136.4c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0 96H136.1c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-192H136.6c-4.4 0-8 3.6-8 8l-.1 48c0 4.4 3.6 8 8 8H504c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm106.5-139L338.4 3.7a48.15 48.15 0 0 0-36.9 0L29.5 117C11.7 124.5 0 141.9 0 161.3V504c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8V161.3c0-19.4-11.7-36.8-29.5-44.3z',
      },
    })]);
  },
};

const WashingMachineIcon = {
  name: 'WashingMachineIcon',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '42',
        height: '24',
        viewBox: '0 0 500 550',
        fill: 'currentColor',
        stroke: 'none',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M436,0H76C51.187,0,31,20.187,31,45c0,6.195,0,415.436,0,422c0,24.813,20.187,45,45,45h360c24.813,0,45-20.187,45-45    c0-6.565,0-415.806,0-422C481,20.187,460.813,0,436,0z M451,467c0,8.271-6.729,15-15,15H76c-8.271,0-15-6.729-15-15V150h390V467z     M451,120H61V45c0-8.271,6.729-15,15-15h360c8.271,0,15,6.729,15,15V120     M406,60h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S414.284,60,406,60     M316,60h-30c-8.284,0-15,6.716-15,15s6.716,15,15,15h30c8.284,0,15-6.716,15-15S324.284,60,316,60     M391.002,315.825c0-0.049-0.006-0.096-0.006-0.145C390.824,240.837,330.333,180,256,180c-74.439,0-135,61.009-135,136    s60.561,136,135,136s135-61.009,135-136c0-0.039-0.001-0.078-0.001-0.117C390.999,315.863,391.002,315.845,391.002,315.825z     M256,210c51.966,0,95.228,38.31,103.548,88.421c-6.887-1.854-13.459-4.823-19.479-8.836c-32.391-21.594-74.25-21.594-106.641,0    c-22.283,14.854-51.078,14.855-73.359,0c-1.609-1.073-3.263-2.08-4.937-3.055C167.811,242.375,208.229,210,256,210z M256,422    c-56.863,0-103.31-45.869-104.951-102.879c31.318,16.974,69.251,15.274,99.02-4.573c22.281-14.855,51.076-14.856,73.359,0    c11.178,7.452,23.673,12.415,36.753,14.697C353.706,381.465,309.455,422,256,422',
      },
    })]);
  },
};

const DetergentIcon = {
  name: 'DetergentIcon',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 140 140',
        fill: 'currentColor',
        stroke: 'none',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M82.725,27.12l-.757-1.241A6.8,6.8,0,0,0,77.083,22.7V14.116a1.749,1.749,0,0,0-1.75-1.75H74V7.048A6.758,6.758,0,0,0,67.25.3h-6.5A6.758,6.758,0,0,0,54,7.048v5.318H52.667a1.749,1.749,0,0,0-1.75,1.75v8.529h-.042a6.769,6.769,0,0,0-5.847,3.377l-1.159,2.009A111.906,111.906,0,0,0,28.917,83.873V116.25A11.763,11.763,0,0,0,40.667,128H87.333a11.763,11.763,0,0,0,11.75-11.75V85.331A111.715,111.715,0,0,0,82.725,27.12Zm-40.3,87.372V87.5H95.583v7.531h-44.9a1.75,1.75,0,1,0,0,3.5h44.9v5.953h-44.9a1.75,1.75,0,0,0,0,3.5h44.9v6.508ZM57.5,7.048A3.254,3.254,0,0,1,60.75,3.8h6.5a3.254,3.254,0,0,1,3.25,3.25v5.318h-13Zm-3.083,8.818H73.583v6.779H54.417ZM87.333,124.5H40.667a8.259,8.259,0,0,1-8.25-8.25V83.873A108.407,108.407,0,0,1,46.9,29.78l1.16-2.009a3.259,3.259,0,0,1,2.815-1.626H76.206A3.269,3.269,0,0,1,78.98,27.7l.758,1.24A108.21,108.21,0,0,1,95.561,84H40.675a1.75,1.75,0,0,0-1.75,1.75v30.492a1.75,1.75,0,0,0,1.75,1.75H95.4A8.263,8.263,0,0,1,87.333,124.5Z M69.667,75.417H80.841a6.765,6.765,0,0,0,6.647-7.963A101.327,101.327,0,0,0,74.9,33.5a1.749,1.749,0,0,0-1.506-.858H69.667a6.757,6.757,0,0,0-6.75,6.75V68.667A6.757,6.757,0,0,0,69.667,75.417ZM66.417,39.4a3.254,3.254,0,0,1,3.25-3.25h2.719A97.808,97.808,0,0,1,84.042,68.068a3.273,3.273,0,0,1-.713,2.686,3.237,3.237,0,0,1-2.488,1.163H69.667a3.254,3.254,0,0,1-3.25-3.25Z',
      },
    })]);
  },
};

const AddressCard = {
  name: 'AddressCard',
  functional: true,
  render: function render(h, ctx) {
    return h('svg', {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        stroke: '#5E627A',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
      ...ctx.data,
    }, [h('path', {
      attrs: {
        d: 'M21 4H3C1.89543 4 1 4.89543 1 6V18C1 19.1046 1.89543 20 3 20H21C22.1046 20 23 19.1046 23 18V6C23 4.89543 22.1046 4 21 4Z M14 8.5H19M14 12H19M14 15.5H19 M11 17V15.6667C11 14.9594 10.8156 14.2811 10.4874 13.781C10.1592 13.281 9.71413 13 9.25 13H5.75C5.28587 13 4.84075 13.281 4.51256 13.781C4.18437 14.2811 4 14.9594 4 15.6667V17 M11 17V15.6667C11 14.9594 10.8156 14.2811 10.4874 13.781C10.1592 13.281 9.71413 13 9.25 13H5.75C5.28587 13 4.84075 13.281 4.51256 13.781C4.18437 14.2811 4 14.9594 4 15.6667V17 M9 9.5C9 10.3284 8.32843 11 7.5 11C6.67157 11 6 10.3284 6 9.5C6 8.67157 6.67157 8 7.5 8C8.32843 8 9 8.67157 9 9.5Z',
      },
    })]);
  },
};

/**
 * @param {import("vue").VueConstructor} Vue
 */
export function install(Vue) {
  if (install.installed) return;
  install.installed = true;

  const icons = [
    FilePlusIcon,
    FileMinusIcon,
    TagIcon,
    NavigationIcon,
    MapPinIcon,
    SettingsIcon,
    MenuIcon,
    UserIcon,
    ArrowLeftIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    BarChart2Icon,
    CheckIcon,
    FileTextIcon,
    LogInIcon,
    LogOutIcon,
    Trash2Icon,
    AwardIcon,
    XIcon,
    CheckCircleIcon,
    EyeIcon,
    XCircleIcon,
    BriefcaseIcon,
    HomeIcon,
    ClipboardIcon,
    FolderPlusIcon,
    BoxIcon,
    TagIcon,
    WindIcon,
    PlusIcon,
    SlidersIcon,
    DropletIcon,
    ShoppingCartIcon,
    HeartIcon,
    ScissorsIcon,
    XOctagonIcon,
    ActivityIcon,
    PackageIcon,
    UsersIcon,
    AlertCircleIcon,
    ThermometerIcon,
    Minimize2Icon,
    Maximize2Icon,
    DashboardIcon,
    EditIcon,
    SearchIcon,
    WarehouseIcon,
    WashingMachineIcon,
    ArchiveIcon,
    SunIcon,
    MoonIcon,
    LayersIcon,
    XSquareIcon,
    PrinterIcon,
    RotateCwIcon,
    ServerIcon,
    SpeakerIcon,
    TruckIcon,
    CameraIcon,
    CopyIcon,
    AlertTriangleIcon,
    InfoIcon,
    PaperclipIcon,
    DetergentIcon,
    RepeatIcon,
    MoreHorizontalIcon,
    ExternalLinkIcon,
    MonitorIcon,
    PlayCircleIcon,
    BookOpenIcon,
    CircleIcon,
    ClockIcon,
    RefreshCwIcon,
    BookIcon,
    TrendingUpIcon,
    PocketIcon,
    UserPlusIcon,
    FlagIcon,
    AddressCard,
  ];

  for (const icon of icons) {
    Vue.component(icon.name, icon);
  }
}

export default {
  install,
};
