<template>
  <div id="app">
    <menu-frame
      class="mb-36"
      @update:side-bar-hidden="(b) => (margin = !b)"
      :links="parsedLinks"
    />
    <div class="super-container" :class="{ margin: margin }">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <br />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import './assets/fonts/style.css';
import './assets/scss/invision.scss';

import MenuFrame from '@/components/Navegacao/MenuFrame';
import loginService from '@/services/login';
import { routes } from '@/router';
import { testPath } from '@/helpers/common';

export default {
  name: 'App',
  components: {
    MenuFrame,
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
  data() {
    return {
      margin: false,
      loginChangeCallback: null,
      routes,
      links: process.env.VUE_APP_PROJECT === 'materdei' ? [
        {
          to: {
            name: 'home',
          },
          name: 'Dashboard',
          icon: 'dashboard',
        },
        {
          name: 'Registro de Procedimento',
          icon: 'map-pin',
          children: [],
        },
        {
          to: { name: 'relatorios' },
          name: 'Relatórios',
          icon: 'fileText',
        },
        {
          to: { name: 'setor' },
          name: 'Setor',
          icon: 'navigation',
        },
        {
          name: 'Materiais',
          icon: 'briefcase',
          children: [
            {
              to: {
                name: 'material',
              },
              name: 'Material',
              icon: 'package',
            },
            {
              to: {
                name: 'materialTemporario',
              },
              name: 'Material Temporário',
              icon: 'clock',
            },
            {
              to: {
                name: 'materialMedico',
              },
              name: 'Material Médico',
              icon: 'thermometer',
            },
            {
              to: {
                name: 'materialSemQRCode',
              },
              name: 'Material Sem QR Code',
              icon: 'book',
            },
            {
              to: {
                name: 'opmeImportarMapa',
              },
              name: 'Importar Mapa',
              icon: 'paperclip',
            },
            {
              to: {
                name: 'classeMaterial',
              },
              name: 'Classe de Material',
              icon: 'folderPlus',
            },
            // {
            //   to: {
            //     name: 'tipoMaterial',
            //   },
            //   name: 'Tipo de Material',
            //   icon: 'tag',
            // },
            {
              to: {
                name: 'tipoDefeitoMaterial',
              },
              name: 'Tipo de Defeito de Material',
              icon: 'x-octagon',
            },
            {
              to: {
                name: 'tipoInstrumento',
              },
              name: 'Tipo de Instrumento',
              icon: 'paperclip',
            },
            {
              to: {
                name: 'tipoPerfuroCortante',
              },
              name: 'Tipo de Perfuro Cortantes',
              icon: 'scissors',
            },
            {
              to: {
                name: 'MateriaisDeletados',
              },
              name: 'Materiais Deletados',
              icon: 'box',
            },
          ],
        },
        {
          name: 'Equipamentos',
          icon: 'washing-machine',
          children: [
            {
              to: {
                name: 'tipoEmbalagem',
              },
              name: 'Tipo de Embalagem',
              icon: 'box',
            },
            {
              to: {
                name: 'tipoEsterilizacao',
              },
              name: 'Tipo de Esterilização ou Desinfecção',
              icon: 'clipboard',
            },
            {
              to: {
                name: 'tipoLavagemAutomatizada',
              },
              name: 'Tipo de Lavagem Automatizada',
              icon: 'droplet',
            },
            {
              to: {
                name: 'equipamento',
              },
              name: 'Equipamento',
              icon: 'wind',
            },
            {
              to: {
                name: 'loteDetergente',
              },
              name: 'Lote de Detergente',
              icon: 'detergent',
            },
            {
              to: {
                name: 'loteEsterilizacao',
              },
              name: 'Lote de Esterilização',
              icon: 'washing-machine',
            },
          ],
        },
        {
          name: 'Ferramentas',
          icon: 'layers',
          children: [
            {
              to: {
                name: 'abortoProcesso',
              },
              name: 'Aborto de Processos',
              icon: 'x-square',
            },
            {
              to: {
                name: 'impressaoEmLote',
              },
              name: 'Impressão de etiquetas',
              icon: 'printer',
            },
            {
              to: {
                name: 'consultaProtocolo',
              },
              name: 'Consulta de protocolos',
              icon: 'search',
            },
            {
              to: {
                name: 'motivoRetorno',
              },
              name: 'Motivos de Retorno',
              icon: 'repeat',
            },
            {
              to: {
                name: 'tutorial',
              },
              name: 'Tutoriais',
              icon: 'monitor',
            },
            {
              to: {
                name: 'controleEstoque',
              },
              name: 'Controle de Estoque',
              icon: 'book-open',
            },
            {
              to: {
                name: 'controleInventario',
              },
              name: 'Controle de Inventário',
              icon: 'book-open',
            },
          ],
        },
        {
          name: 'Diversos',
          icon: 'users',
          children: [
            {
              to: {
                name: 'usuario',
              },
              name: 'Usuário',
              icon: 'user',
            },
            {
              to: {
                name: 'medico',
              },
              name: 'Médico',
              icon: 'activity',
            },
            {
              to: {
                name: 'fornecedor',
              },
              name: 'Fornecedor',
              icon: 'shopping-cart',
            },
            {
              to: {
                name: 'fabricante',
              },
              name: 'Fabricante',
              icon: 'shopping-cart',
            },
          ],
        },
        {
          name: 'Configurações',
          icon: 'settings',
          children: [
            {
              to: {
                name: 'configPage',
              },
              name: 'Configurações de sistema',
              icon: 'settings',
            },
            {
              to: {
                name: 'configMeta',
              },
              name: 'Configurações de metas',
              icon: 'trendingUp',
            },
            {
              to: {
                name: 'configFluxo',
              },
              name: 'Configurações de fluxo',
              icon: 'repeat',
            },
          ],
        },
      ] : [
        {
          to: {
            name: 'home',
          },
          name: 'Dashboard',
          icon: 'dashboard',
        },
        {
          name: 'Registro de Procedimento',
          icon: 'map-pin',
          children: [],
        },
        {
          to: { name: 'relatorios' },
          name: 'Relatórios',
          icon: 'fileText',
        },
        {
          to: { name: 'setor' },
          name: 'Setor',
          icon: 'navigation',
        },
        {
          name: 'Materiais',
          icon: 'briefcase',
          children: [
            {
              to: {
                name: 'material',
              },
              name: 'Material',
              icon: 'package',
            },
            {
              to: {
                name: 'materialTemporario',
              },
              name: 'Material Temporário',
              icon: 'clock',
            },
            {
              to: {
                name: 'materialMedico',
              },
              name: 'Material Médico',
              icon: 'thermometer',
            },
            {
              to: {
                name: 'tipoInstrumento',
              },
              name: 'Tipo de Instrumento',
              icon: 'paperclip',
            },
            {
              to: {
                name: 'MateriaisDeletados',
              },
              name: 'Materiais Deletados',
              icon: 'box',
            },
          ],
        },
        {
          name: 'Equipamentos',
          icon: 'washing-machine',
          children: [
            {
              to: {
                name: 'tipoEmbalagem',
              },
              name: 'Tipo de Embalagem',
              icon: 'box',
            },
            {
              to: {
                name: 'equipamento',
              },
              name: 'Equipamento',
              icon: 'wind',
            },
            {
              to: {
                name: 'loteDetergente',
              },
              name: 'Lote de Detergente',
              icon: 'detergent',
            },
          ],
        },
        {
          name: 'Ferramentas',
          icon: 'layers',
          children: [
            {
              to: {
                name: 'abortoProcesso',
              },
              name: 'Aborto de Processos',
              icon: 'x-square',
            },
            {
              to: {
                name: 'impressaoEmLote',
              },
              name: 'Impressão de etiquetas',
              icon: 'printer',
            },
            {
              to: {
                name: 'motivoRetorno',
              },
              name: 'Motivos de Retorno',
              icon: 'repeat',
            },
          ],
        },
        {
          name: 'Diversos',
          icon: 'users',
          children: [
            {
              to: {
                name: 'usuario',
              },
              name: 'Usuário',
              icon: 'user',
            },
            {
              to: {
                name: 'medico',
              },
              name: 'Médico',
              icon: 'activity',
            },
            {
              to: {
                name: 'fornecedor',
              },
              name: 'Fornecedor',
              icon: 'shopping-cart',
            },
            {
              to: {
                name: 'fabricante',
              },
              name: 'Fabricante',
              icon: 'shopping-cart',
            },
          ],
        },
        {
          name: 'Configurações',
          icon: 'settings',
          children: [
            {
              to: {
                name: 'configPage',
              },
              name: 'Configurações de sistema',
              icon: 'settings',
            },
            {
              to: {
                name: 'configMeta',
              },
              name: 'Configurações de metas',
              icon: 'trendingUp',
            },
            {
              to: {
                name: 'configFluxo',
              },
              name: 'Configurações de fluxo',
              icon: 'repeat',
            },
          ],
        },
      ],
      parsedLinks: [],
    };
  },
  computed: {
    ...mapState({
      subSetores: (state) => state.subSetores,
      configuracao: (state) => state.configuracao,
      unidades: (state) => state.genericData.unidade,
    }),

    title() {
      let title = 'InVision';
      if (this.configuracao && this.configuracao.nomeFantasia) {
        title = `${title} - ${this.configuracao.nomeFantasia}`;
      }
      return title;
    },
  },
  watch: {
    subSetores: {
      deep: true,
      handler(v) {
        if (v && v.length) {
          this.setSubSetoresLinks();
          this.updateLinks();
        }
      },
    },
  },
  methods: {
    setSubSetoresLinks() {
      // ! Melhorar implementação hardcoded
      if (this.subSetores.some((subSetor) => subSetor.chave === 'arsenalCentroCirurgico')) {
        this.links.splice(2, 0, {
          to: { name: 'centroCirurgico' },
          name: 'Centro Cirúrgico',
          icon: 'scissors',
        });
      }

      const linkProcessos = this.links.find((link) => link.name === 'Registro de Procedimento');
      linkProcessos.children = this.subSetores
        //! Código hardcoded para separar o arsenal do centro cirúrgico dos processos - Melhorar implementação
        .filter((subSetor) => subSetor.chave !== 'arsenalCentroCirurgico')
        .map((subSetor) => ({
          to: { path: `/Processamento/${subSetor.chave}` },
          name: subSetor.nome,
          icon: 'map-pin',
        }));
    },
    updateLinks() {
      const links = this.links.map((link) => ({
        ...link,
        children: link.children && link.children.map((c) => ({
          ...c,
        })),
      }));

      this.parsedLinks = links.filter((ln, index) => {
        if (ln.to) {
          const permission = loginService.verifyPermissions(this.getPermissionsByRouteName(ln.to.name));
          const area = loginService.verifyAreas(this.getAreasByRouteName(ln.to.name));
          return permission && area;
        }
        if (ln.children.length) {
          links[index].children = ln.children.filter((ln2) => {
            const permission = loginService.verifyPermissions(
              this.getPermissionsByRouteName(ln2.to.name || ln2.to.path),
            );
            const area = loginService.verifyAreas(
              this.getAreasByRouteName(ln2.to.name || ln2.to.path),
            );
            return permission && area;
          });
          return links[index].children.length > 0;
        }
        return false;
      });
    },
    getPermissionsByRouteName(name) {
      const route = this.$router.options.routes.find((rout) => (
        rout.name === name || testPath(rout.path, name)
      ));
      if (!route) return false;
      return route.meta.perms;
    },
    getAreasByRouteName(name) {
      const route = this.$router.options.routes.find((rout) => (
        rout.name === name || testPath(rout.path, name)
      ));
      if (!route) return false;
      return route.meta.areas;
    },
  },

  provide() {
    return { parentValidator: this.$parent.$validator };
  },

  async created() {
    if (this.subSetores) {
      this.setSubSetoresLinks();
    }

    await this.$store.dispatch('getConfigs');
    this.$store.dispatch('getMetas');
    this.$store.dispatch('getDashboardOptionsFromApi');

    this.updateLinks();
    this.loginChangeCallback = this.updateLinks.bind(this);
    this.$root.$on('loginChange', this.loginChangeCallback);

    loginService.validateToken();
    if (loginService.getUnidade()) {
      const unidade = this.unidades.find((u) => u.id_unidade === loginService.getUnidade().id_unidade);
      loginService.setUnidade(unidade);
    }

    this.$validator.extend('greaterThanZero', {
      getMessage: (field) => `${field} precisa ser maior do que zero`,
      validate: (value) => {
        if (value > 0) return true;
        return false;
      },
    });

    this.$validator.extend(
      'lessThan',
      (value, [state]) => {
        if (value > state) return false;
        return true;
      },
    );

    this.$validator.extend(
      'betweenFiftyPorcentLessAndGreater',
      (value, [state]) => {
        if (value < Math.ceil(state * 0.5) || value > Math.floor(state * 1.5)) return false;
        return true;
      },
    );

    // Aqui o valor de referência 88 que é usado é devido à quantidade de caracteres que o arquivo .zpl comporta
    this.$validator.extend(
      'maxLengthOfJustAndObsConcat',
      (value, [parameter]) => {
        if (parameter) return !(value.length + parameter.length > 88);
        if (!parameter) return !(value.length > 88);
        return true;
      },
    );

    this.$validator.extend(
      'isHigherThanMaxZplChars',
      (value) => !(value.length > 100),
    );

    this.$validator.extend(
      'numeroMaximoDeMateriaisEnxovaisEsterilizados',
      (value, [parameter]) => !(+value > +parameter),
    );

    this.$validator.extend(
      'validateHourInput',
      (value) => {
        const h = (value.substr(0, 2));
        const m = (value.substr(3, 5));
        if (Number.isNaN(+h) || +h > 23 || h.length !== 2) {
          return false;
        }
        if (Number.isNaN(+m) || +m > 59 || m.length !== 2) {
          return false;
        }
        return true;
      },
    );

    this.$validator.extend('hasToBeTrue', {
      validate: (value) => value === true,
    });

    this.$validator.extend(
      'atLeastOne',
      (value, [state]) => state,
      { hasTarget: true },
    );

    this.$validator.extend('validaPhone', {
      validate: (value) => {
        let number = typeof value === 'string' ? value : value.toString();
        number = number.replace(/\D/g, '');
        const regTel = new RegExp('^[1-9]{2}9?[0-9]{8}$');
        return regTel.test(number);
      },
    });
  },
  beforeDestroy() {
    this.$root.$off('loginChange', this.loginChangeCallback);
  },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  border: 0;
}

* {
  font-family: 'Poppins', 'sans-serif' !important;
}

#app {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  min-height: 100vh;
  background-color: whitesmoke;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 64px;
}

.mb-36 {
  margin-bottom: 36px;
}

.pointer {
  cursor: pointer;
}

.tooltip-icon {
  width: 16px;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
  color: #35384D;
}
</style>

<style scoped>
.super-container {
  transition: margin-left 0.3s ease-in-out;
}
.super-container.margin {
  margin-left: 70px;
}

</style>
