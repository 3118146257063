import genericRequest from './genericRequest';
import store from '../store';
import constants from '../helpers/constants';
import loginService from './login';

const self = {
  getIdUnidadeContorno() {
    return self.getUnidadeByIdUnidade(1); // 1 => contorno
  },
  async getUnidades() {
    const { state: { genericData } } = store;
    if (genericData && genericData.unidade) {
      return genericData.unidade;
    }
    const unidades = await genericRequest.getWithoutPagination({}, 'unidade');
    store.commit('setGenericData', { unidade: unidades });
    return unidades;
  },
  async getUnidadeByIdUnidade(id_unidade) {
    if (!id_unidade) return null;
    const unidades = await self.getUnidades();
    return unidades.find((unidade) => unidade.id_unidade === id_unidade);
  },
  async getNomeUnidade(material, procedimento) {
    if (constants.idTipoMaterialUnidadeFromProcedimento.includes(material.id_tipo_material)) {
      if (procedimento) {
        const unidade = await self.getUnidadeByIdUnidade(procedimento.id_unidade_destino);
        if (unidade && unidade.nome) return unidade.nome;
      }
      return '-';
    }
    return material.unidadePatrimonio ? material.unidadePatrimonio.nome : 'Rede';
  },
  async getCmeByIdUnidade(id_unidade) {
    const unidade = await self.getUnidadeByIdUnidade(id_unidade);
    return unidade.setor.find((s) => s.UnidadeSetor.central_esterilizacao);
  },
  async getBlocoByIdUnidade(id_unidade) {
    const unidade = await self.getUnidadeByIdUnidade(id_unidade);
    return unidade.setor.find((s) => s.UnidadeSetor.bloco);
  },
  async getBlocoAndCmeByIdUnidade(id_unidade) {
    const unidade = await self.getUnidadeByIdUnidade(id_unidade);
    return unidade.setor.filter((s) => s.UnidadeSetor.bloco || s.UnidadeSetor.central_esterilizacao);
  },
  getUnidadesUsuario() {
    const user = loginService.getUser();
    return user.unidades;
  },
};

export default self;
