/* eslint-disable import/no-cycle */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */

import VueRouter from 'vue-router';
import loginService from './services/login';
import { perms, areas } from './helpers/auth';
import store from './store';

export const routes = [
  // Dashboard
  {
    name: 'home',
    path: '/',
    component: (resolve) => require(['@/views/Dashboard/Home.vue'], resolve),
    meta: {
      title: 'Dashboard',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },

  // Login
  {
    name: 'login',
    path: '/login',
    component: (resolve) => require(['@/views/401.vue'], resolve),
    meta: {
      title: 'Login',
      perms: perms(),
      areas: areas([]),
    },
  },
  {
    name: 'redefinicao',
    path: '/redefinicao',
    component: (resolve) => require(['@/views/Redefinicao.vue'], resolve),
    meta: {
      title: 'Redefinição',
      perms: perms(),
      areas: areas([]),
    },
  },
  {
    name: 'selecaoUnidade',
    path: '/selecaoUnidade',
    component: (resolve) => require(['@/views/Autenticacao/SelecaoUnidade.vue'], resolve),
    meta: {
      title: 'Seleção de Unidade',
      perms: perms(),
      areas: areas([]),
    },
  },
  {
    name: '403',
    path: '/forbidden',
    component: (resolve) => require(['@/views/403.vue'], resolve),
    meta: {
      title: 'Permissão insuficiente',
      perms: perms(),
      areas: areas([]),
    },
  },
  {
    name: 'logout',
    path: '/logout',
    component: (resolve) => require(['@/views/Logout.vue'], resolve),
    meta: {
      title: 'Logout',
      perms: perms(),
      areas: areas([]),
    },
  },

  {
    name: 'configPage',
    path: '/configuracoes',
    component: (resolve) => require(['@/views/ConfigPage/ConfigPage.vue'], resolve),
    meta: {
      title: 'conf',
      perms: perms(['rw_unidade']),
      areas: areas(['cme']),
    },
  },

  {
    name: 'configFluxo',
    path: '/configuracoesFluxo',
    component: (resolve) => require(['@/views/ConfigFluxo/List.vue'], resolve),
    meta: {
      title: 'confFluxo',
      perms: perms(['rw_fluxo']),
    },
  },

  // TODO: Verificar quais são as permissões para alteração de metas
  {
    name: 'configMeta',
    path: '/configuracoesMeta',
    component: (resolve) => require(['@/views/ConfigMeta/ConfigMeta.vue'], resolve),
    meta: {
      title: 'configMeta',
      perms: perms(['rw_unidade']),
      areas: areas(['cme']),
    },
  },

  // Materiais
  {
    name: 'material',
    path: '/material',
    component: (resolve) => require(['@/views/Material/List.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['rw_registro_entrada', 'r_material']),
    },
  },
  {
    name: 'relatorioMateriaisParados',
    path: '/relatorioMateriaisParados',
    component: (resolve) => require(['@/views/Relatorios/RelatorioMateriaisParados/index.vue'], resolve),
    meta: {
      breadcrumb: 'Relatório de materiais parados',
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_mat_parados']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioRecebimentoMateriaisComAviso',
    path: '/relatorioRecebimentoMateriaisComAviso',
    component: (resolve) => require(['@/views/Relatorios/RecebimentoMateriaisComAviso/index.vue'], resolve),
    meta: {
      breadcrumb: 'Relatório de materiais recebidos com aviso',
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_mat_receb_aviso']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'materialEditar',
    path: '/material/editar/:id',
    component: (resolve) => require(['@/views/Material/Edit.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['r_visualizar', 'rw_registro_entrada']),
      areas: areas(['cme', 'spr', 'opme']),
    },
  },
  {
    name: 'materialAdicionar',
    path: '/material/adicionar',
    component: (resolve) => require(['@/views/Material/Add.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['rw_material']),
      areas: areas(['cme', 'opme']),
    },
  },
  {
    name: 'materialRastrear',
    path: '/material/rastrear/:id',
    component: (resolve) => require(['@/views/Material/Rastreabilidade/Rastreabilidade.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['r_visualizar', 'rw_registro_entrada', 'r_material']),
      areas: areas([]),
    },
  },

  {
    name: 'materialTemporario',
    path: '/materialTemporario',
    component: (resolve) => require(['@/views/MaterialTemporario/List.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['rw_registro_entrada', 'r_material']),
    },
  },

  {
    name: 'materialTemporarioAdicionar',
    path: '/materialTemporario/adicionar',
    component: (resolve) => require(['@/views/MaterialTemporario/Add.vue'], resolve),
    meta: {
      title: 'Materiais',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme', 'opme']),
    },
  },

  {
    name: 'materialTemporarioEditar',
    path: '/materialTemporario/editar/:id',
    component: (resolve) => require(['@/views/MaterialTemporario/Edit.vue'], resolve),
    meta: {
      title: 'Materiais Temporários',
      perms: perms(['r_visualizar', 'rw_registro_entrada', 'rw_cadastro_rapido']),
      areas: areas(['cme', 'spr', 'opme']),
    },
  },

  // Material Medico
  {
    name: 'materialMedico',
    path: '/materialMedico',
    component: (resolve) => require(['@/views/Material/MaterialMedico/List.vue'], resolve),
    meta: {
      title: 'Materiais Médico',
      perms: perms(['rw_registro_entrada', 'r_material']),
      areas: areas([]),
    },
  },
  {
    name: 'materialMedicoEditar',
    path: '/materialMedico/editar/:id',
    component: (resolve) => require(['@/views/Material/Edit.vue'], resolve),
    props: { tipoMaterial: 'medico' },
    meta: {
      title: 'Materiais Médico',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'materialMedicoAdicionar',
    path: '/materialMedico/adicionar',
    component: (resolve) => require(['@/views/Material/Add.vue'], resolve),
    props: { tipoMaterial: 'medico' },
    meta: {
      title: 'Materiais Médico',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'opmeImportarMapa',
    path: '/materialopme/mapa',
    component: (resolve) => require(['@/views/ImportarMapa/ImportarMapa.vue'], resolve),
    meta: {
      title: 'Importar mapa cirúrgico',
      perms: perms(['r_visualizar', 'rw_registro_entrada', 'rw_cadastro_rapido']),
      areas: areas(['opme', 'cme']),
    },
  },

  {
    name: 'tipoEsterilizacao',
    path: '/tipoEsterilizacao',
    component: (resolve) => require(['@/views/TipoEsterilizacao/List.vue'], resolve),
    meta: {
      title: 'Tipos de Esterilizações',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoEsterilizacaoEditar',
    path: '/tipoEsterilizacao/editar/:id',
    component: (resolve) => require(['@/views/TipoEsterilizacao/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Esterilizações',
      perms: perms(['re_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoEsterilizacaoAdicionar',
    path: '/tipoEsterilizacao/adicionar',
    component: (resolve) => require(['@/views/TipoEsterilizacao/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Esterilizações',
      perms: perms(['rw_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoDesinfeccao',
    path: '/tipoDesinfeccao',
    component: (resolve) => require(['@/views/TipoDesinfeccao/List.vue'], resolve),
    meta: {
      title: 'Tipos de Desinfecções',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoDesinfeccaoEditar',
    path: '/tipoDesinfeccao/editar/:id',
    component: (resolve) => require(['@/views/TipoDesinfeccao/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Desinfecções',
      perms: perms(['re_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoDesinfeccaoAdicionar',
    path: '/tipoDesinfeccao/adicionar',
    component: (resolve) => require(['@/views/TipoDesinfeccao/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Desinfeccoes',
      perms: perms(['rw_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoLavagemAutomatizada',
    path: '/tipoLavagemAutomatizada',
    component: (resolve) => require(['@/views/TipoLavagemAutomatizada/List.vue'], resolve),
    meta: {
      title: 'Tipos de Lavagens Automatizadas',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoLavagemAutomatizadaEditar',
    path: '/tipoLavagemAutomatizada/editar/:id',
    component: (resolve) => require(['@/views/TipoLavagemAutomatizada/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Lavagens Automatizadas',
      perms: perms(['re_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoLavagemAutomatizadaAdicionar',
    path: '/tipoLavagemAutomatizada/adicionar',
    component: (resolve) => require(['@/views/TipoLavagemAutomatizada/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Lavagens Automatizadas',
      perms: perms(['rw_esterilizacao']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Teste
  {
    name: 'tipoTeste',
    path: '/tipoTeste',
    // component: (resolve) => require(['@/views/TipoDesinfeccao/List.vue'], resolve),
    meta: {
      title: 'Tipos de Testes',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },

  // Esterilização
  {
    name: 'loteEsterilizacao',
    path: '/loteEsterilizacao',
    component: (resolve) => require(['@/views/LoteEsterilizacao/index.vue'], resolve),
    meta: {
      title: 'Lote Esterilização',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'equipamento',
    path: '/equipamento',
    component: (resolve) => require(['@/views/Equipamento/List.vue'], resolve),
    meta: {
      title: 'Equipamentos',

      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'equipamentoEditar',
    path: '/equipamento/editar/:id',
    component: (resolve) => require(['@/views/Equipamento/Edit.vue'], resolve),
    meta: {
      title: 'Equipamentos',
      perms: perms(['re_esterilizacao']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'equipamentoAdicionar',
    path: '/equipamento/adicionar',
    component: (resolve) => require(['@/views/Equipamento/Add.vue'], resolve),
    meta: {
      title: 'Equipamentos',
      perms: perms(['rw_esterilizacao']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Material
  {
    name: 'tipoMaterial',
    path: '/tipoMaterial',
    component: (resolve) => require(['@/views/TipoMaterial/List.vue'], resolve),
    meta: {
      title: 'Tipos de Materiais',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoMaterialEditar',
    path: '/tipoMaterial/editar/:id',
    component: (resolve) => require(['@/views/TipoMaterial/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Materiais',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoMaterialAdicionar',
    path: '/tipoMaterial/adicionar',
    component: (resolve) => require(['@/views/TipoMaterial/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Materiais',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Embalagens
  {
    name: 'tipoEmbalagem',
    path: '/tipoEmbalagem',
    component: (resolve) => require(['@/views/TipoEmbalagem/List.vue'], resolve),
    meta: {
      title: 'Tipos de Embalagens',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoEmbalagemEditar',
    path: '/tipoEmbalagem/editar/:id',
    component: (resolve) => require(['@/views/TipoEmbalagem/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Embalagens',
      perms: perms(['re_embalagem']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoEmbalagemAdicionar',
    path: '/tipoEmbalagem/adicionar',
    component: (resolve) => require(['@/views/TipoEmbalagem/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Embalagens',
      perms: perms(['rw_embalagem']),
      areas: areas(['cme']),
    },
  },

  // Fornecedor
  {
    name: 'fornecedor',
    path: '/fornecedor',
    component: (resolve) => require(['@/views/Fornecedor/List.vue'], resolve),
    meta: {
      title: 'Fornecedor',
      perms: perms(['r_visualizar']),
      areas: areas(['cme', 'opme']),
    },
  },
  {
    name: 'fornecedorEditar',
    path: '/fornecedor/editar/:id',
    component: (resolve) => require(['@/views/Fornecedor/Edit.vue'], resolve),
    meta: {
      title: 'Fornecedor',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme', 'opme']),
    },
  },
  {
    name: 'fornecedorAdicionar',
    path: '/fornecedor/adicionar',
    component: (resolve) => require(['@/views/Fornecedor/Add.vue'], resolve),
    meta: {
      title: 'Fornecedor',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme', 'opme']),
    },
  },
  // Fabricante
  {
    name: 'fabricante',
    path: '/fabricante',
    component: (resolve) => require(['@/views/Fabricante/List.vue'], resolve),
    meta: {
      title: 'Fabricante',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'fabricanteEditar',
    path: '/fabricante/editar/:id',
    component: (resolve) => require(['@/views/Fabricante/Edit.vue'], resolve),
    meta: {
      title: 'Fabricante',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'fabricanteAdicionar',
    path: '/fabricante/adicionar',
    component: (resolve) => require(['@/views/Fabricante/Add.vue'], resolve),
    meta: {
      title: 'Fabricante',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },

  // Médicos
  {
    name: 'medico',
    path: '/medico',
    component: (resolve) => require(['@/views/Medico/List.vue'], resolve),
    meta: {
      title: 'Médicos',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'medicoEditar',
    path: '/medico/editar/:id',
    component: (resolve) => require(['@/views/Medico/Edit.vue'], resolve),
    meta: {
      title: 'Médicos',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'medicoAdicionar',
    path: '/medico/adicionar',
    component: (resolve) => require(['@/views/Medico/Add.vue'], resolve),
    meta: {
      title: 'Médicos',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Perfuro Cortantes
  {
    name: 'tipoPerfuroCortante',
    path: '/tipoPerfuroCortante',
    component: (resolve) => require(['@/views/TipoPerfuroCortante/List.vue'], resolve),
    meta: {
      title: 'Tipos de Perfuro Cortantes',

      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoPerfuroCortanteEditar',
    path: '/tipoPerfuroCortante/editar/:id',
    component: (resolve) => require(['@/views/TipoPerfuroCortante/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Perfuro Cortantes',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoPerfuroCortanteAdicionar',
    path: '/tipoPerfuroCortante/adicionar',
    component: (resolve) => require(['@/views/TipoPerfuroCortante/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Perfuro Cortantes',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Defeito de Material
  {
    name: 'tipoDefeitoMaterial',
    path: '/tipoDefeitoMaterial',
    component: (resolve) => require(['@/views/TipoDefeitoMaterial/List.vue'], resolve),
    meta: {
      title: 'Tipos de Defeito de Material',

      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoDefeitoMaterialEditar',
    path: '/tipoDefeitoMaterial/editar/:id',
    component: (resolve) => require(['@/views/TipoDefeitoMaterial/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Defeito de Material',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoDefeitoMaterialAdicionar',
    path: '/tipoDefeitoMaterial/adicionar',
    component: (resolve) => require(['@/views/TipoDefeitoMaterial/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Defeito de Material',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // Tipos de Instrumento
  {
    name: 'tipoInstrumento',
    path: '/tipoInstrumento',
    component: (resolve) => require(['@/views/TipoInstrumento/List.vue'], resolve),
    meta: {
      title: 'Tipos de Instrumento',

      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoInstrumentoEditar',
    path: '/tipoInstrumento/editar/:id',
    component: (resolve) => require(['@/views/TipoInstrumento/Edit.vue'], resolve),
    meta: {
      title: 'Tipos de Instrumento',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'tipoInstrumentoAdicionar',
    path: '/tipoInstrumento/adicionar',
    component: (resolve) => require(['@/views/TipoInstrumento/Add.vue'], resolve),
    meta: {
      title: 'Tipos de Instrumento',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // Classes de Materiais
  {
    name: 'classeMaterial',
    path: '/classeMaterial',
    component: (resolve) => require(['@/views/ClasseMaterial/List.vue'], resolve),
    meta: {
      title: 'Classes de Materiais',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'classeMaterialEditar',
    path: '/classeMaterial/editar/:id',
    component: (resolve) => require(['@/views/ClasseMaterial/Edit.vue'], resolve),
    meta: {
      title: 'Classes de Materiais',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'classeMaterialAdicionar',
    path: '/classeMaterial/adicionar',
    component: (resolve) => require(['@/views/ClasseMaterial/Add.vue'], resolve),
    meta: {
      title: 'Classes de Materiais',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // Lotes de Detergente
  {
    name: 'loteDetergente',
    path: '/loteDetergente',
    component: (resolve) => require(['@/views/LoteDetergente/List.vue'], resolve),
    meta: {
      title: 'Lote de detergente',
      perms: perms(['r_visualizar', 'rw_detergente']),
      areas: areas(['cme']),
    },
  },
  // Motivos de Retorno
  {
    name: 'motivoRetorno',
    path: '/motivoRetorno',
    component: (resolve) => require(['@/views/MotivoRetorno/List.vue'], resolve),
    meta: {
      title: 'Motivos de Retorno',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'loteDetergenteAdicionar',
    path: '/loteDetergente/adicionar',
    component: (resolve) => require(['@/views/LoteDetergente/Add.vue'], resolve),
    meta: {
      title: 'Lote de detergente',
      perms: perms(['rw_material', 'rw_detergente']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'motivoRetornoEditar',
    path: '/motivoRetorno/editar/:id',
    component: (resolve) => require(['@/views/MotivoRetorno/Edit.vue'], resolve),
    meta: {
      title: 'Motivos de Retorno',
      perms: perms(['re_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'motivoRetornoAdicionar',
    path: '/motivoRetorno/adicionar',
    component: (resolve) => require(['@/views/MotivoRetorno/Add.vue'], resolve),
    meta: {
      title: 'Motivos de Retorno',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },

  // setor
  {
    name: 'setor',
    path: '/setor',
    component: (resolve) => require(['@/views/Setor/List.vue'], resolve),
    meta: {
      title: 'Setores',
      perms: perms(['r_visualizar']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'setorEditar',
    path: '/setor/editar/:id',
    component: (resolve) => require(['@/views/Setor/Edit.vue'], resolve),
    meta: {
      title: 'Setores',
      perms: perms(['re_unidade']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'setorAdicionar',
    path: '/setor/adicionar',
    component: (resolve) => require(['@/views/Setor/Add.vue'], resolve),
    meta: {
      title: 'Setores',
      perms: perms(['rw_unidade']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'Processamento',
    path: '/Processamento/:chave',
    component: (resolve) => require(['@/processos/Processamento.vue'], resolve),
    meta: {
      title: 'Registro de Processamento',
      perms: perms(['rw_registro_entrada']),
      areas: areas(['cme', 'spr', 'cci']),
    },
    props: true,
  },
  // Aborto Processos
  {
    name: 'abortoProcesso',
    path: '/abortoProcesso',
    component: (resolve) => require(['@/views/AbortoProcesso/AbortoProcessoPage.vue'], resolve),
    meta: {
      title: 'Aborto de processos',
      perms: perms(['rd_encerrar_ciclo']),
      areas: areas(['cme']),
    },
  },

  // Consulta de Protocolo
  {
    name: 'consultaProtocolo',
    path: '/consultaProtocolo',
    component: (resolve) => require(['@/views/Protocolo/List.vue'], resolve),
    meta: {
      title: 'Consulta de protocolo',
      perms: perms(['r_visualizar', 'r_consulta_protocolo']),
    },
  },
  {
    name: 'protocoloRecebimentoMaterialExpurgo',
    path: '/consultaProtocolo/visualizar/:protocolo/:id',
    component: (resolve) => require(['@/views/Protocolo/VisualizarProtocolo/Visualizar.vue'], resolve),
    meta: {
      title: 'Protocolo',
      perms: perms(['r_visualizar', 'r_consulta_protocolo']),
    },
  },

  // Usuários
  {
    name: 'usuario',
    path: '/usuario',
    component: (resolve) => require(['@/views/Usuario/List.vue'], resolve),
    meta: {
      title: 'Usuários',
      perms: perms(['rw_usuario']),
    },
  },
  {
    name: 'usuarioEditar',
    path: '/usuario/editar/:id',
    component: (resolve) => require(['@/views/Usuario/Edit.vue'], resolve),
    meta: {
      title: 'Usuários',
      perms: perms(['rw_usuario']),
    },
  },
  {
    name: 'usuarioAdicionar',
    path: '/usuario/adicionar',
    component: (resolve) => require(['@/views/Usuario/Add.vue'], resolve),
    meta: {
      title: 'Usuários',
      perms: perms(['rw_usuario']),
    },
  },
  // Relatórios
  {
    name: 'relatorios',
    path: '/relatorios',
    component: (resolve) => require(['@/views/Relatorios/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms([
        'r_visualizar',
        'r_rel_aborto',
        'r_rel_inventario',
        'r_rel_ciclos_forcados',
        'r_rel_mat_med_parados',
        'r_rel_emprestimos',
        'r_rel_mat_medico',
        'r_rel_mat_opme',
        'r_rel_mat_parados',
        'r_rel_mat_receb_aviso',
        'r_rel_esterilizacao',
        'r_rel_processos',
        'r_rel_nao_conformidade',
      ]),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEsterilizacao',
    path: '/relatorio/esterilizacao',
    component: (resolve) => require(['@/views/Relatorios/Esterilizacao/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_esterilizacao']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEsterilizacaoEquipamento',
    path: '/relatorio/esterilizacao/equipamento',
    props: true,
    component: (resolve) => require(['@/views/Relatorios/Esterilizacao/Equipamento.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_esterilizacao']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioProcessos',
    path: '/relatorio/processos',
    component: (resolve) => require(['@/views/Relatorios/Processos/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_processos']),
    },
  },
  {
    name: 'relatorioNaoConformidades',
    path: '/relatorio/naoConformidades',
    component: (resolve) => require(['@/views/Relatorios/NaoConformidades/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_nao_conformidade']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEntradasOpme',
    path: '/relatorio/entradasOpme',
    component: (resolve) => require(['@/views/Relatorios/EntradasOpme/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_mat_opme']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEntradasMedico',
    path: '/relatorio/medico',
    component: (resolve) => require(['@/views/Relatorios/EntradasMedico/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_mat_medico']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioAbortoDeProcessos',
    path: '/relatorio/abortoDeProcessos',
    component: (resolve) => require(['@/views/Relatorios/AbortoDeProcessos/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_aborto']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioInventario',
    path: '/relatorio/inventario',
    component: (resolve) => require(['@/views/Relatorios/Inventario/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_inventario']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioItens',
    path: '/relatorio/itens',
    component: (resolve) => require(['@/views/Relatorios/Itens/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_inventario']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEncerramentosForcados',
    path: '/relatorio/EncerramentoForcado',
    component: (resolve) => require(['@/views/Relatorios/EncerramentoForcado/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_ciclos_forcados']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioMedicoParados',
    path: '/relatorio/RelatorioMedicoParados',
    component: (resolve) => require(['@/views/Relatorios/RelatorioMedicoParados/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_mat_med_parados']),
      areas: areas(['cme', 'cci']),
    },
  },
  {
    name: 'relatorioEmprestimos',
    path: '/relatorio/Emprestimos',
    component: (resolve) => require(['@/views/Relatorios/Emprestimos/index.vue'], resolve),
    meta: {
      title: 'Relatórios',
      perms: perms(['r_visualizar', 'r_rel_emprestimos']),
      areas: areas(['cme', 'cci']),
    },
  },
  // Tutorial
  // necessário adicionar as outras permissões
  // w = write; e = edit; d = delete
  {
    name: 'tutorial',
    path: '/tutorial',
    component: (resolve) => require(['@/views/Tutorial/Tutorial.vue'], resolve),
    meta: {
      title: 'Tutoriais',
      perms: perms(['r_tutorial']),
    },
  },

  // Impressão em Lote
  {
    name: 'impressaoEmLote',
    path: '/impressaoEmLote',
    component: (resolve) => require(['@/views/ImpressaoEmLote/Impressao.vue'], resolve),
    meta: {
      title: 'Impressão de etiquetas',
      perms: perms(['rw_registro_entrada']),
      areas: areas(['cme', 'opme']),
    },
  },

  // Controle de Estoque
  {
    name: 'controleEstoque',
    path: '/controleEstoque',
    component: (resolve) => require(['@/views/ControleEstoque/ControleEstoque.vue'], resolve),
    meta: {
      title: 'Controle de Estoque',
      perms: perms(['r_controle_estoque']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'instrumentoAdicionar',
    path: '/controleEstoque/instrumentoAdicionar',
    component: (resolve) => require(['@/views/ControleEstoque/Form.vue'], resolve),
    meta: {
      title: 'Instrumento',
      perms: perms(['r_controle_estoque']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'instrumentoEditar',
    path: '/controleEstoque/instrumentoEditar/:id',
    component: (resolve) => require(['@/views/ControleEstoque/Edit.vue'], resolve),
    meta: {
      title: 'Instrumento',
      perms: perms(['r_controle_estoque']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'reposicaoMaterial',
    path: '/controleEstoque/reposicao/:id',
    component: (resolve) => require(['@/views/ControleEstoque/FormReposicao'], resolve),
    meta: {
      title: 'Reposicao',
      perms: perms(['r_controle_estoque']),
      areas: areas(['cme']),
    },
  },

  // Controle de Inventário
  {
    name: 'controleInventario',
    path: '/controleInventario',
    component: (resolve) => require(['@/views/ControleInventario/ControleInventario.vue'], resolve),
    meta: {
      title: 'Controle de Inventario',
      perms: perms(['r_controle_estoque']),
      areas: areas(['cme']),
    },
  },

  // Material sem QR Code
  {
    name: 'materialSemQRCode',
    path: '/materialSemQRCode',
    component: (resolve) => require(['@/views/MaterialSemQRCode/List.vue'], resolve),
    meta: {
      title: 'Listagem de materiais sem QR Code',
      perms: perms(['rw_material', 'r_material']),
      areas: areas([]),
    },
  },
  {
    name: 'materialSemQRCodeHistorico',
    path: '/materialSemQRCode/historico/:id',
    component: (resolve) => require(['@/views/MaterialSemQRCode/Historico.vue'], resolve),
    meta: {
      title: 'Histórico de registros',
      perms: perms([]),
      areas: areas(['cme', 'spr', 'cci']),
    },
  },
  {
    name: 'materialSemQRCodeEditar',
    path: '/materialSemQRCode/editar/:id',
    component: (resolve) => require(['@/views/MaterialSemQRCode/Edit.vue'], resolve),
    props: { tipoMaterial: 'materialGenerico' },
    meta: {
      title: 'Materiais Sem QR Code',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme', 'spr']),
    },
  },
  {
    name: 'materialSemQRCodeAdicionar',
    path: '/materialSemQRCode/adicionar/',
    component: (resolve) => require(['@/views/MaterialSemQRCode/Add.vue'], resolve),
    props: { tipoMaterial: 'materialGenerico' },
    meta: {
      title: 'Materiais Sem QR Code',
      perms: perms(['rw_cadastro_rapido']),
      areas: areas(['cme', 'spr']),
    },
  },
  {
    name: 'MateriaisDeletados',
    path: '/listaDeletados',
    component: (resolve) => require(['@/views/Material/Deletados/index.vue'], resolve),
    meta: {
      breadcrumb: 'Relatório de materiais deletados',
      title: 'Relatórios',
      perms: perms(['rw_material']),
      areas: areas(['cme']),
    },
  },
  {
    name: 'centroCirurgico',
    path: '/centroCirurgico',
    component: (resolve) => require(['@/views/CentroCirurgico/SaidaArsenal.vue'], resolve),
    meta: {
      breadcrumb: 'Arsenal do centro cirúrgico',
      title: 'Centro Cirúrgico',
      perms: perms(['rw_registro_entrada']),
      areas: areas(['cme']),
    },
  },
  // 404 (precisa ser a última rota deste arquivo)
  {
    name: '404',
    path: '*',
    component: (resolve) => require(['@/views/404.vue'], resolve),
    meta: {
      title: 'Página não encontrada',
      perms: perms(),
      areas: areas([]),
    },
  },
];

export const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit('setPreviousRoute', from);

  const user = loginService.getUser();

  const unidade = loginService.getUnidade();

  if (!to.meta.perms) { // página pública
    next();
  } else if (!loginService.isLoggedIn()) {
    next({ name: 'login', params: { next: to } }); // não logado
  } else if (user && user.redefinicao && to.name !== 'redefinicao') {
    next({ name: 'redefinicao', params: { next: to } }); // redefinicao de senha
  } else if (!unidade && to.name !== 'selecaoUnidade' && to.name !== 'redefinicao') {
    next({ name: 'selecaoUnidade', params: { next: to } }); // seleção de unidade
  } else if (!loginService.verifyPermissions(to.meta.perms) || !loginService.verifyAreas(to.meta.areas)) {
    next({ name: '403', params: { next: to } }); // sem permissão
  } else next(); // acesso permitido
});
