<template class="">
  <div :class="listClass" class=" transition">
    <cardless-base>
      <slot name="header" v-if="!disabledTitle">
        <PageTitle :title="`Listagem de ${title}`" />
      </slot>

      <DataTable
        :loading="loading"
        :colunas="parsedCols"
        :linhas="linhas"
        :errMsg="errMsg"
        @state-change="setStateDataTable"
        :noedit="!editRoute || !CAN_WRITE"
        :nosearch="pagination"
        :nofilters="nofilters"
        :noadd="noadd || !CAN_WRITE"
        :name="name"
        :hasPagination="hasPagination"
        @addRow="redirectToAddRoute"
        @toggleFilters="toggleFilters"
        :rastreabilidade="!!rastreabilidadeRoute"
        :reposicao="!!reposicaoRoute"
        :historico="!!historicoRoute"
        :deletados="hasDeleted"
        :canClearFilters="canClearFilters"
        @clear-filters="clearFilters"
        :detalhes="hasDetalhes"
        :detalhesInventario="hasDetalhesInventario"
        :fotografia="hasFotografia"
        @showDetalhes="showDetalhes"
        @showDetalhesInventario="showDetalhesInventario"
        @reativa="showModal"
        @open-modal-fotografia="openModalFotografia"
        :key="refresh"
      >
        <template #botoes>
          <slot name="botoes" />
        </template>

        <template #results-page v-if="pagination">
          <select
            class="invision-select"
            :value="rowsPerPage"
            @input="changeResults"
            data-cy="resultados-pagina"
          >
            <option value="10">10 por página</option>
            <option value="20">20 por página</option>
            <option value="50">50 por página</option>
            <option value="100">100 por página</option>
            <option v-if="canShowAllResults" value="-1">Mostrar todos</option>
          </select>
        </template>

        <template v-for="col in cols" v-slot:[col]="{ value, item }">
          <slot :name="col" :value="value" :item="item">
            <router-link
              v-if="value && value.rota && value.nome && value.id"
              :to="{ name: value.rota, params: { id: value.id } }"
              title="Ir para a edição"
            >
              <edit-icon size="16" />
              {{ value.nome }}
            </router-link>
          </slot>
        </template>
      </DataTable>

      <nav aria-label="Navegar entre as páginas" class="paginador" v-if="page">
        <div>
          Mostrando resultados {{ first }} a {{ last }} de um total de
          <strong>{{ count }}</strong
          >.
        </div>
        <ul class="pagination justify-content-center mb-0">
          <b-pagination
            id="page"
            v-model="currentPage"
            :total-rows="count"
            :per-page="rowsPerPage"

          ></b-pagination>
        </ul>
      </nav>
    </cardless-base>
    <!-- template-base -->

    <div class="transition filters-aside p-4" :class="filterAsideClasses">
      <b-row align-h="between" class="pt-4 pb-4">
        <b-col> <h2 class="m-0"> Filtro </h2> </b-col>
        <b-col class="text-right">
          <b-button
            type="button"
            variant="none"
            class="sm primary-light-contained-button"
            :disabled="loading"
            @click="searchHandler"
            >Buscar</b-button
          >
        </b-col>
      </b-row>
      <slot name="filters" />
    </div>
  </div>
</template>

<script>
import loginService from '@/services/login';
import { show403 } from '@/helpers/auth';

import GenericApi from '@/services/genericRequest';

import DataTable from '@/components/Table/DataTable';
import CardlessBase from '@/templates/CardlessBase';
import PageTitle from '@/templates/PageTitle';

export default {
  name: 'GenericTable',
  components: {
    DataTable,
    CardlessBase,
    PageTitle,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    addRoute: {
      type: String,
      required: false,
    },
    editRoute: {
      type: String,
      required: false,
    },
    apiFunction: {
      type: String,
      required: false,
      default() {
        return 'get';
      },
    },
    api: {
      type: Object,
      required: false,
      default() {
        return GenericApi;
      },
    },
    route: {
      type: String,
      required: true,
    },
    cols: {
      type: Array,
      required: true,
    },
    colsMap: {
      type: Function,
      required: true,
    },
    colsName: {
      type: Array,
      required: true,
    },
    canShowAllResults: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default() {
        return {};
      },
    },
    fixedFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    permissionsToWrite: {
      type: Array,
      default() {
        return [];
      },
    },
    idKey: {
      type: String,
      default: '',
    },
    nofilters: {
      type: Boolean,
      default: false,
    },
    noadd: {
      type: Boolean,
      default: false,
    },
    editRouteIndex: {
      type: Function,
      default: () => 0,
    },
    hasPagination: {
      type: Boolean,
      default: false,
    },
    rastreabilidadeRoute: {
      type: String,
      default: '',
    },
    historicoRoute: {
      type: String,
      default: '',
    },
    disabledTitle: {
      type: Boolean,
      default: false,
    },
    reposicaoRoute: {
      type: String,
      default: '',
    },
    hasDetalhes: {
      type: Boolean,
      default: false,
    },
    hasFotografia: {
      type: Boolean,
      default: false,
    },
    hasDeleted: {
      type: Boolean,
      default: false,
    },
    disabledInputs: {
      type: Array,
      required: false,
    },
    materiaisGenericos: {
      type: Array,
      required: false,
    },
    deletadosRoute: {
      type: String,
      default: '',
    },
    customSearchHandler: {
      type: Function || null,
      default: null,
      required: false,
    },
    hasDetalhesInventario: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lista: [],
      loading: false,
      errMsg: '',
      pagination: false,
      page: 0,
      pages: 1,
      rowsPerPage: 20,
      first: 0,
      last: 0,
      count: 0,
      currentPage: 1,

      CAN_WRITE: loginService.verifyPermissions(this.permissionsToWrite),

      showingFilters: false,
      hasFiltersApplied: false,
      refresh: 0,
    };
  },
  watch: {
    currentPage() {
      this.paginate(this.currentPage);
    },
    materiaisGenericos: {
      async handler() {
        await this.update();
      },
    },
  },
  computed: {
    linhas() {
      return this.lista.map((row) => {
        const idKey = this.idKey || Object.keys(row).find((key) => key.slice(0, 2) === 'id');

        const indexRoute = this.editRouteIndex(row);

        const linha = {
          href: { name: this.editRoutes[indexRoute], params: { id: row[idKey] } },
          cols: this.colsMap(row),
          id: row[idKey],
        };
        if (this.rastreabilidadeRoute) {
          linha.rastrearHref = { name: this.rastreabilidadeRoute, params: { id: row[idKey] } };
        }
        if (this.historicoRoute) {
          linha.historicoHref = {
            name: this.historicoRoute,
            params: { id: row[idKey], materialGenerico: row },
          };
        }
        if (this.reposicaoRoute) {
          linha.reposicaoHref = { name: this.reposicaoRoute, params: { id: row[idKey] } };
        }
        if (this.hasDetalhes) {
          linha.tipoItem = row.tipoItem;
        }
        if (this.hasDeleted) {
          linha.reativaHref = {
            name: this.hasDeleted,
            params: { id: row[idKey], material: row },
          };
          linha.tipoItem = row.tipoItem;
        }
        if (this.hasDetalhesInventario) {
          linha.detalhesInventarioHref = {
            name: this.hasDetalhesInventario,
            params:
            {
              id: row[idKey],
              descricao: row.descricao,
              id_tipo_material: row.id_tipo_material,
            },
          };
          linha.tipoItem = row.tipoItem;
        }

        return linha;
      });
    },
    parsedCols() {
      return this.cols.map((c, idx) => {
        if (this.colsName[idx]) return c;
        return { value: c, sortable: false };
      });
    },
    editRoutes() {
      return this.editRoute ? this.editRoute.split(',') : [];
    },
    listClass() {
      return this.showingFilters ? 'w-75' : 'w-100';
    },
    filterAsideClasses() {
      return this.showingFilters ? 'show' : 'hidden';
    },
    canClearFilters() {
      if (this.filtersCopy) {
        const keys = Object.keys(this.filtersCopy);
        const op = keys.some((key) => this.filtersCopy[key]);
        return op;
      }
      if (!this.filters || !this._events['clear-filters']) { // eslint-disable-line no-underscore-dangle
        return false;
      }
      if (this.hasFiltersApplied) return true;
      const filtersKeys = Object.keys(this.filters);
      if (filtersKeys.some((key) => this.filters[key])) {
        return true;
      }
      return false;
    },
    filtersCopy() {
      if (!this.disabledInputs || !this.disabledInputs.length) return null;

      const copy = { ...this.filters };
      const disabledInputsKeys = this.disabledInputs.map((input) => input.name);

      disabledInputsKeys.forEach((key) => {
        delete copy[key];
      });
      return copy;
    },
  },

  async mounted() {
    this.update();
    window.addEventListener('keyup', this.handleSubmitOnEnter);
  },

  destroyed() {
    window.removeEventListener('keyup', this.handleSubmitOnEnter);
  },

  methods: {
    searchHandler() {
      if (this.customSearchHandler) {
        return this.customSearchHandler();
      }
      this.update(1, 20);
    },
    openModalFotografia(v) {
      this.$emit('open-modal-fotografia', v);
    },
    handleSubmitOnEnter(event) {
      if (event.code === 'Enter') {
        this.update(1, 20);
      }
    },
    showDetalhes(v) {
      this.$emit('showDetalhes', v);
    },
    showDetalhesInventario(v) {
      this.$emit('showDetalhesInventario', v);
    },
    showModal(v) {
      this.$emit('reativa', v);
    },
    setStateDataTable() {
      this.page = 0;
      this.update(1, this.rowsPerPage);
    },
    changeResults(event) {
      this.page = 0;
      this.update(1, event.target.value);
    },
    paginate(p) {
      if (p === this.page) return;
      this.page = p;
      this.update(this.page, this.rowsPerPage);
    },
    update(page = 1, rowsPerPage = 20) {
      this.loading = true;
      this.pagination = true;

      if (this.currentPage !== 1 && page === 1) {
        this.page = 1;
        this.currentPage = 1;
      }

      const { fixedFilters } = this;
      const filters = { ...this.filters };

      // Substitui os possíveis caracteres especiais na descrição pelo código de caracteres reservados
      const caracteresEspeciais = ['+', '=', '#', '&', ':'];
      Object.keys(filters).forEach((item) => {
        if (item === 'descricao') {
          if (filters.descricao) {
            caracteresEspeciais.forEach((char, index) => {
              const indexChar = filters.descricao.indexOf(caracteresEspeciais[index]);
              if (indexChar !== -1) {
                filters.descricao = filters.descricao.replace(filters.descricao[indexChar], encodeURIComponent(char));
              }
            });
          }
        }
      });
      const filtersKeys = Object.keys(this.filters);
      if (filtersKeys.some((key) => this.filters[key])) {
        this.hasFiltersApplied = true;
      } else {
        this.hasFiltersApplied = false;
      }

      const combinedFilters = { ...filters, ...fixedFilters };
      const finalfilters = Object.keys(combinedFilters).reduce((Acum, key) => {
        if (combinedFilters[key]) return { ...Acum, [key]: combinedFilters[key] };
        return Acum;
      }, {});
      this.$store.commit('setFiltersStored', { [this.$router.history.current.name]: { ...filters } });
      if (this.route === 'materialGenerico') {
        const initialRows = (page - 1) * rowsPerPage + 1;
        this.first = initialRows;
        this.last = (rowsPerPage - 1) + initialRows;
        this.count = this.materiaisGenericos.length;
        if (this.last > this.count) this.last = this.count;
        this.page = page;
        this.pages = Math.ceil(this.materiaisGenericos.length / rowsPerPage);
        this.lista = this.materiaisGenericos.slice((initialRows - 1), rowsPerPage + initialRows);
        this.errMsg = '';
        this.loading = false;
        return 0;
      }
      return this.api[this.apiFunction](
        { page, rowsPerPage, filters: finalfilters },
        this.route,
      )
        .then((res) => {
          if (res.rows) {
            // paginação ativada
            this.page = page;
            this.pages = res.pages;
            this.first = res.count === 0 ? 0 : (page - 1) * rowsPerPage + 1;
            this.last = res.rows.length + rowsPerPage * (page - 1);
            this.count = res.count;
            this.rowsPerPage = rowsPerPage;
          }
          this.lista = res.rows || res;
          this.errMsg = '';
        })
        .catch((reason) => {
          this.lista = [];
          this.first = 0;
          this.last = 0;
          this.count = 0;
          this.page = 1;
          this.errMsg = reason.response.data.error
            ? reason.response.data.error.errorMessage
            : reason.toString();
        })
        .then(() => {
          this.loading = false;
        });
    },
    redirectToAddRoute() {
      if (!this.CAN_WRITE) {
        show403();
        return;
      }

      this.$router.push({ name: this.addRoute });
    },
    toggleFilters() {
      this.showingFilters = !this.showingFilters;
    },
    clearFilters() {
      if (this._events['clear-filters']) { // eslint-disable-line no-underscore-dangle
        this.$emit('clear-filters');
        this.update();
      }
    },
  },
};
</script>
<style>
.page-item .page-link {
  color: #0BB390;
}

.page-item.disabled .page-link {
  font-weight: 400;
}

.page-item.active .page-link {
  background-color: #0BB390 !important;
  color: white;

  font-weight: 500;

  border: 1px solid #0BB390;
}
</style>

<style lang="scss" scoped>
$primary-color-50: #209f85;
$primary-color-40: #21CCA9;
$neutral-color-80: #35384D;
$neutral-color-70: #5E627A;
$neutral-color-60: #7E829F;

.w-75 {
  padding: 0 0 0 1.6rem;
}

.invision-select {
  width: auto;

  color: #5e627a;
  background-color: #f0f1fc;

  border: none;
  margin-left: 12px;
}

.paginador {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feather {
  margin-bottom: 4px;
}
.spacer {
  margin-left: auto;
}

.transition {
  transition: 0.3s ease-in-out;
}

.filters-aside {
  z-index: 100;
  position: fixed;
  min-height: 100%;
  right: 0;
  top: 64px;
  background-color: white;
}
.filters-aside.show {
  width: 22%;
}
.filters-aside.hidden {
  width: 0 !important;
  padding: 0 !important;
}

.toggle-button {
  color: $neutral-color-80;
}

label {
  color: $neutral-color-70;
}

</style>
